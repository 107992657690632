<template>
  <div class="modal fade" ref="highlightModal" id="highlightModal" tabindex="-1" role="dialog" aria-labelledby="highlightModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="d-flex justify-content-between align-items-center px-3 text-black-50 border-bottom">
          <small>{{ $t('js.highlight_modal.header') }}</small>
          <button type="button" class="btn btn-link text-black-50" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
        </div>
        <div class="modal-body">
          <div v-if="activeHighlight">
            <PresentPost v-if="highlightedPost" :post="highlightedPost" class="mb-3"/>
            <div v-if="activeHighlight.highlight_message">
              <label class="font-weight-bold">{{ $t('js.highlight_modal.message_label_frontend') }}</label>
              <div style="white-space: pre-wrap; overflow-wrap: break-word;">{{ activeHighlight.highlight_message }}</div>
            </div>
          </div>
          <div v-else>
            {{ $t('js.highlight_modal.no_active_highlight') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex"
import PresentPost from "../../shared/PresentPost.vue"

export default {
  name: "HighlightModal",
  components: {PresentPost},
  computed: {
    ...mapGetters(['firstAvailableLocale']),
    ...mapState(['activeHighlight']),
    highlightedPost(){
      return this.activeHighlight.highlight_post
    }
  },
  watch: {
    activeHighlight: function(newHighlight, oldHighlight){
      if (!newHighlight) {
        $('#highlightModal').modal('hide');
      } else if (!oldHighlight || Object.values(newHighlight).toString() !== Object.values(oldHighlight).toString()) $('#highlightModal').modal('show');
    }
  }
}
</script>